import ActionsLayer from 'components/actionsLayer';
import Button from 'components/button';
import { DataTableAction } from 'components/dataTable/types/dataTableAction.types';
import { Grid } from 'context';
import Tooltip from 'context/tooltip/tooltip'
import { uniqueId } from 'lodash';
import React, { isValidElement } from 'react';
import { classesBuilder } from 'shared/utils';
import * as style from '../../style/dataTable.module.scss';
import DataTableCell, { DataTableCellProps } from '../dataTableCell/dataTableCell';
/**
 * Represents a cell within a DataTable that contains action buttons or components.
 * This component is designed to render a set of action buttons or custom components based on the provided data and configuration.
 *
 *
 * @interface DataTableCellActionProps
 * @extends DataTableCellProps
 * @extends DataTableAction
 */
interface DataTableCellActionProps extends DataTableCellProps, DataTableAction {
  /**
   * The data object associated with the row where this cell is located.
   */
  data: any;
}

/**
 * A React component responsible for rendering action buttons or components within a DataTable cell.
 *
 * @component
 * @name DataTableCellAction
 *
 * @param {DataTableCellActionProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the DataTableCellAction component.
 */
const DataTableCellAction: React.FC<DataTableCellActionProps> = ({
  grouped,
  vertical,
  items,
  data,
  ...props
}) => {
  const renderActions = items?.(data)?.map((item) => {
    if (isValidElement(item)) {
      return item;
    }
    return <Tooltip title={item.description} key={uniqueId('dataTableActionItem_')}>
      <Button {...item} onClick={() => item?.onClick?.(data)} />
    </Tooltip>
  });

  return (
    <DataTableCell {...props} align='right' className={ classesBuilder(style, { 'actionColumn': true }) }>
      {(!grouped && items?.(data)?.length) && (
        <Grid gap={4} column={vertical} alignContent='right'>
          { renderActions }
        </Grid>
      )}
      {(grouped && items?.(data)?.length) && (
        <Grid alignContent='right' growing>
          <ActionsLayer
            position="top"
            align="center"
            vertical
            actions={items?.(data).map((item) => ({
              label: item.label ?? '',
              icon: item.icon,
              onClick: () => item?.onClick?.(data)
            }))}
          />
        </Grid>
      )}
    </DataTableCell>
  );
}

export default DataTableCellAction;
